import React from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import NavbarInteractive from '../../components/navbar-interactive'
import './csam.css'

const Csam = (props) => {
  return (
    <div className="csam-container">
      <Helmet>
        <title>Heist CSAE Policy</title>
        <meta property="og:title" content="Heist’s Standards for Addressing Child Sexual Abuse and Exploitation (CSAE)" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <NavbarInteractive rootClassName="navbar-interactive-root-class-name"></NavbarInteractive>
      <div className="csam-container1">
        <span className="csam-text">CSAE Policy</span>
      </div>
      <div className="csam-container2">
        <div className="csam-container3">
          <div className="csam-container4">
            <Script
              html={`<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap') @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')
</style>
<style>
[data-custom-class='body'],
[data-custom-class='body'] * {
    background: transparent !important;
}

[data-custom-class='title'],
[data-custom-class='title'] * {
    font-family: Poppins !important;
    font-size: 26px !important;
    color: #000000 !important;
}

[data-custom-class='subtitle'],
[data-custom-class='subtitle'] * {
    font-family: Poppins !important;
    color: #595959 !important;
    font-size: 14px !important;
}

[data-custom-class='heading_1'],
[data-custom-class='heading_1'] * {
    font-family: Poppins !important;
    font-size: 19px !important;
    color: #000000 !important;
}

[data-custom-class='heading_2'],
[data-custom-class='heading_2'] * {
    font-family: Poppins !important;
    font-size: 17px !important;
    color: #000000 !important;
}

[data-custom-class='body_text'],
[data-custom-class='body_text'] * {
    color: #595959 !important;
    font-size: 14px !important;
    font-family: Poppins !important;
}

[data-custom-class='link'],
[data-custom-class='link'] * {
    color: #3030F1 !important;
    font-size: 14px !important;
    font-family: Poppins !important;
    word-break: break-word !important;
}
</style>
<div data-custom-class="body">
    <div align="center" style="text-align: left;">
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div align="center" class="MsoNormal" style="text-align:center;line-height:150%;"><a name="_2cipo4yr3w5d"></a>
                <div align="center" class="MsoNormal" style="line-height: 22.5px;">
                    <div align="center" class="MsoNormal" style="line-height: 150%;"><a name="_gm5sejt4p02f"></a>
                        <div align="center" class="MsoNormal" data-custom-class="title" style="text-align: left; line-height: 1.5;"><strong><span style="line-height: 22.5px; font-size: 26px;">Heist’s Standards for Addressing Child Sexual Abuse and Exploitation (CSAE)</span></strong></div>
                        <div align="center" class="MsoNormal" style="line-height: 22.5px; text-align: left;"><a name="_7m5b3xg56u7y"></a></div>
                    </div>
                </div>
                <div align="center" class="MsoNormal" style="line-height: 17.25px; text-align: left;"><br></div>
                <div align="center" class="MsoNormal" style="line-height: 17.25px; text-align: left;"><span style="font-size: 11pt; line-height: 16.8667px;"><strong><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89);">Last updated</span> </span></strong>
                        <bdt class="question"><strong><span data-custom-class="body_text">Jan 21, 2025</span></strong></bdt>
                    </span></div>
                <div align="center" class="MsoNormal" style="line-height: 17.25px; text-align: left;"><br></div>
                <div align="center" class="MsoNormal" style="line-height: 17.25px; text-align: left;"><span style="font-size: 11pt; line-height: 16.8667px;"><br></span></div>
            </div>
        </div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;"><br></div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 115%;"><a name="_a7mwfgcrtsqn"></a></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
                    At <bdt class="question">Heist</bdt>, ensuring the safety of all users, particularly children, is our top priority. We are committed to taking comprehensive measures to detect, prevent, and respond to Child Sexual Abuse and Exploitation (CSAE). Below, we outline our globally accessible policy and standards around CSAE to inform our users, developers, and partners of our unwavering commitment to child safety.
            </div>
        </div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 1;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5;"><strong><span style="font-size: 19px;">TABLE OF CONTENTS</span></strong></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><a data-custom-class="link" href="#scope">1. SCOPE OF CSAE STANDARDS</a></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><a data-custom-class="link" href="#ztp">2. ZERO TOLERANCE POLICY</a></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><a data-custom-class="link" href="#prevention">3. PREVENTION MEASURES</a></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><a data-custom-class="link" href="#reporting">4. REPORTING AND SUPPORT</a></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><a data-custom-class="link" href="#education">5. EDUCATING AND EMPOWERING THE COMMUNITY</a></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><a data-custom-class="link" href="#transparency">6. TRANSPARENCY AND ACCOUNTABILITY</a></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><a data-custom-class="link" href="#enforcement">7. ENFORCEMENT OF STANDARDS</a></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
        </div>



        <!-- SECTION START -->
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="heading_1" id="scope" style="line-height: 1.5;"><a name="_4rd71iod99ud"></a><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>1.</strong><strong> SCOPE OF CSAE STANDARDS</strong></span></span></strong></div>
        </div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 1;"><br></div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
                        
                    </span> <bdt class="block-component"></bdt><bdt class="statement-end-if-in-editor"></bdt>Our CSAE standards apply to all content, behavior, and interactions on the Heist platform, including but not limited to:
                    
                </span></span></div>
                <br>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                <bdt data-type="conditional-block">
                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                <bdt data-type="conditional-block">
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">1.1  Grooming: Efforts to build trust with a child to exploit or abuse them.</span></bdt>
                                                    <br><br>
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">1.2  Sextortion: Coercing or blackmailing a child for sexually explicit content or actions.</span></bdt>
                                                    <br><br>
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">1.3 Trafficking: Using the platform to exploit or trade children for sexual purposes.</span></bdt>
                                                    <br><br>
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">1.4  Content: Sharing, creating, or soliciting content that sexually abuses or exploits a child.</span></bdt>
                                                </bdt>
                                            </bdt>
                                        </span></bdt>
                                </bdt>
                            </bdt>
                        </span></span></span></div>
        </div>
        <br>
        <!-- SECTION END -->

        <!-- SECTION START -->
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="heading_1" id="ztp" style="line-height: 1.5;"><a name="_4rd71iod99ud"></a><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>2.</strong><strong>ZERO TOLERANCE POLICY</strong></span></span></strong></div>
        </div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 1;"><br></div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
                        
                    </span> <bdt class="block-component"></bdt><bdt class="statement-end-if-in-editor"></bdt>Heist enforces a zero-tolerance policy for any form of CSAE. This includes:
                    
                </span></span></div>
                <br>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                <bdt data-type="conditional-block">
                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                <bdt data-type="conditional-block">
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">2.1 Immediate removal of any content or accounts engaging in CSAE-related activity.</span></bdt>
                                                    <br><br>
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">2.2  Permanent banning of offending users or entities from the platform.</span></bdt>
                                                    <br><br>
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">2.3 Full cooperation with law enforcement and relevant organizations to report and prosecute offenders.</span></bdt>
                                                </bdt>
                                            </bdt>
                                        </span></bdt>
                                </bdt>
                            </bdt>
                        </span></span></span></div>
        </div>
        <br>
        <!-- SECTION END -->


        <!-- SECTION START -->
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="heading_1" id="prevention" style="line-height: 1.5;"><a name="_4rd71iod99ud"></a><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>3.</strong><strong>PREVENTION MEASURES</strong></span></span></strong></div>
        </div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 1;"><br></div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
                        
                    </span> <bdt class="block-component"></bdt><bdt class="statement-end-if-in-editor"></bdt>Heist employs multiple measures to prevent CSAE on the platform, including:
                    
                </span></span></div>
                <br>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                <bdt data-type="conditional-block">
                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                <bdt data-type="conditional-block">
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">3.1 Proactive Monitoring: Regular reviews to identify and flag potentially harmful behavior or content.</span></bdt>
                                                    <br><br>
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">3.2 Content Moderation: A trained team that reviews reported content and behavior.</span></bdt>
                                                    <br><br>
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">3.3 Age Requirement: Age-appropriate control to restrict minors from using the app.</span></bdt>
                                                </bdt>
                                            </bdt>
                                        </span></bdt>
                                </bdt>
                            </bdt>
                        </span></span></span></div>
        </div>
        <br>
        <!-- SECTION END -->

        <!-- SECTION START -->
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="heading_1" id="reporting" style="line-height: 1.5;"><a name="_4rd71iod99ud"></a><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>4.</strong><strong>REPORTING AND SUPPORT</strong></span></span></strong></div>
        </div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 1;"><br></div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
                        
                    </span> <bdt class="block-component"></bdt><bdt class="statement-end-if-in-editor"></bdt>We empower users to take action when they encounter CSAE-related activity:
                    
                </span></span></div>
                <br>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                <bdt data-type="conditional-block">
                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                <bdt data-type="conditional-block">
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">4.1 Report Abuse: Users can report suspicious content or behavior via the in-app reporting tool.
                                                    </span></bdt>
                                                    <br><br>
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">4.2 Help Center: Guidance for users who suspect or experience CSAE-related behavior. Visit Help Center
                                                    </span></bdt>
                                                    <br><br>
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">4.3 Collaboration with Authorities: All CSAE reports are immediately escalated to relevant law enforcement agencies and organizations like the National Center for Missing & Exploited Children (NCMEC).
                                                    </span></bdt>
                                                </bdt>
                                            </bdt>
                                        </span></bdt>
                                </bdt>
                            </bdt>
                        </span></span></span></div>
        </div>
        <br>
        <!-- SECTION END -->

        <!-- SECTION START -->
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="heading_1" id="education" style="line-height: 1.5;"><a name="_4rd71iod99ud"></a><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>5.</strong><strong>EDUCATING AND EMPOWERING THE COMMUNITY</strong></span></span></strong></div>
        </div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 1;"><br></div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
                        
                    </span> <bdt class="block-component"></bdt><bdt class="statement-end-if-in-editor"></bdt>Heist actively educates its community about CSAE risks by:
                    
                </span></span></div>
                <br>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                <bdt data-type="conditional-block">
                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                <bdt data-type="conditional-block">
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">5.1 Publishing safety tips and guidelines.
                                                    </span></bdt>
                                                    <br><br>
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">5.2 Providing resources to promote digital literacy and safe interactions.
                                                    </span></bdt>
                                                </bdt>
                                            </bdt>
                                        </span></bdt>
                                </bdt>
                            </bdt>
                        </span></span></span></div>
        </div>
        <br>
        <!-- SECTION END -->



        <!-- SECTION START -->
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="heading_1" id="transparency" style="line-height: 1.5;"><a name="_4rd71iod99ud"></a><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>6.</strong><strong>TRANSPARENCY AND ACCOUNTABILITY</strong></span></span></strong></div>
        </div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 1;"><br></div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
                        
                    </span> <bdt class="block-component"></bdt><bdt class="statement-end-if-in-editor"></bdt><!-- graf text here -->
                    
                </span></span></div>
                <br>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                <bdt data-type="conditional-block">
                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                <bdt data-type="conditional-block">
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">6.1 Heist will publish an annual Child Safety Report detailing the actions we take against CSAE.
                                                    </span></bdt>
                                                    <br><br>
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">6.2 We collaborate with industry experts and government bodies to strengthen child safety standards.
                                                    </span></bdt>
                                                </bdt>
                                            </bdt>
                                        </span></bdt>
                                </bdt>
                            </bdt>
                        </span></span></span></div>
        </div>
        <br>
        <!-- SECTION END -->



        <!-- SECTION START -->
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="heading_1" id="enforcement" style="line-height: 1.5;"><a name="_4rd71iod99ud"></a><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>7.</strong><strong>ENFORCEMENT OF STANDARDS</strong></span></span></strong></div>
        </div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 1;"><br></div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
                        
                    </span> <bdt class="block-component"></bdt><bdt class="statement-end-if-in-editor"></bdt>Failure to comply with these standards will result in:
                    
                </span></span></div>
                <br>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                <bdt data-type="conditional-block">
                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                <bdt data-type="conditional-block">
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">7.1 Account Action: Immediate suspension or termination.
                                                    </span></bdt>
                                                    <br><br>
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">7.2 Legal Action: Cooperation with law enforcement in relevant cases.
                                                    </span></bdt>
                                                    <br><br>
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">7.3 Platform Removal: Proactive steps to remove malicious actors permanently.
                                                    </span></bdt>
                                                </bdt>
                                            </bdt>
                                        </span></bdt>
                                </bdt>
                            </bdt>
                        </span></span></span></div>
        </div>
        <br>
        <!-- SECTION END -->

                <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
                        
                    </span> <bdt class="block-component"></bdt><bdt class="statement-end-if-in-editor"></bdt><!-- For more information on our CSAE policies, visit our Community Guidelines and Safety Standards. -->
                    
                </span></span></div>
                <br>

    </div>
</div>



<style>
ul {
    list-style-type: square;
}

ul>li>ul {
    list-style-type: circle;
}

ul>li>ul>li>ul {
    list-style-type: square;
}

ol li {
    font-family: Poppins;
}
</style>
</div>
      `}
            ></Script>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Csam
